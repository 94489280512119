.container {
    font-family: 'Euclid';
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
        button {
            background: white;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            letter-spacing: 0.39998px;
            color: #2563F6;
            border: 1px solid #2563F6;
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
            border-radius: 4px;
            padding: 5px 8px;
            margin-right: 10px;
            transition: all 0.15s ease-in-out;
            &:hover {
                color: #fff;
                background-color: #2563F6;
            }
        }
        .darkBtn{
            color: #fff;
            background-color: #2563F6;
            border-color: #2563F6; 
            margin-left: 8px;
            &:hover {
                color: #2563F6;
                background-color: #fff;
            }
        } 
}

.image {
    @media screen and (max-width: 768px) {
        max-width: 450px
    }
}

.text {
    width: 420px;
    text-align: center;
    .blueTxt {
        font-family: Euclid Medium;
        font-size: 20px;
        line-height: 15px;
        margin-bottom: 10px;
        margin-top: 35px;
        text-align: center;
        color: #2563F6;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        @media screen and (max-width: 768px) {
            font-size: 1.2rem;
        }
    
    }
    > p {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #3F3D56;
        margin-bottom: 20px;
    }
}

